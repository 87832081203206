import React, { Component } from 'react'
import { Hero, Section, Container, NetlifyForm, SEO } from '../webhart-base'
import { gradients, colors, rhythm } from '../webhart-base/utils/style'
import { css } from '@emotion/core'

import Layout from '../components/Layout'

import whatsapp from '../../static/images/icons/whatsapp.svg'
import location from '../../static/images/icons/location.svg'
import mail from '../../static/images/icons/mail.svg'

import Obfuscate from 'react-obfuscate'

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="get in touch!" />
        <Hero size={25} background={gradients.darkBlue} color={colors.orange}>
          <h1>get in touch</h1>
        </Hero>
        <Section>
          <Container width="wide">
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              `}
            >
              <div
                css={css`
                  flex: 0 1 450px;
                `}
              >
                <h3>send message</h3>
                <NetlifyForm
                  name="normal-contact"
                  css={css`
                    text-align: left;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: ${rhythm(1 / 2)};
                    label {
                      margin: 0 0 ${rhythm(1 / 2)};
                      padding: 0 ${rhythm(1 / 2)};
                      flex: 1 1 auto;
                      display: flex;
                      flex-direction: column;
                      input,
                      textarea {
                        background: white;
                        border: none;
                      }
                    }
                  `}
                  buttonStyle={css`
                    align-self: flex-end;
                    margin: 0 ${rhythm(1 / 2)} 0 auto;
                  `}
                >
                  <label
                    css={css`
                      width: 100px;
                    `}
                  >
                    <span>name:</span>
                    <input type="text" name="name" required />
                  </label>
                  <label
                    css={css`
                      width: 200px;
                    `}
                  >
                    <span>email:</span>
                    <input type="email" name="email" required />
                  </label>
                  <label
                    css={css`
                      width: 100%;
                      flex: 1 1 100%;
                      textarea {
                        width: 100%;
                      }
                    `}
                  >
                    <span>message:</span>
                    <textarea name="message" />
                  </label>
                </NetlifyForm>
              </div>
              <div
                css={css`
                  flex: 0 1 350px;

                  text-align: left;
                  p {
                    position: relative;
                    padding-left: 40px;
                    width: 240px;
                    margin: ${rhythm(1)} auto;
                  }
                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin: 0;
                    width: 30px;
                    height: auto;
                  }
                  h3 {
                    text-align: center;
                    grid-column: 1 / 3;
                  }
                `}
              >
                <h3>info</h3>
                <p>
                  <img src={location} alt="location" />
                  Rien Coertjens
                  <br />
                  WEBhart - Bike Breaks
                  <br />
                  C/Nou, 14
                  <br />
                  17001, Girona, Spain
                </p>
                <p>
                  <img src={mail} alt="email" />
                  <Obfuscate email="rien@web-hart.com">
                    rien@web-hart.com
                  </Obfuscate>
                </p>
                <p>
                  <img src={whatsapp} alt="WhatsApp" />
                  <Obfuscate tel="0034722256067">(+34) 722 25 60 67</Obfuscate>
                  <br />
                  please use WhatsApp if possible
                </p>
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default ContactPage
